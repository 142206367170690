@import url("https://fonts.googleapis.com/css2?family=Mansalva&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

::-webkit-scrollbar {
	display: none;
}

* {
	-ms-overflow-style: none;
	scrollbar-width: none;
	scroll-behavior: smooth;
}

.mansalva {
	font-family: "Mansalva", cursive;
}
.poppins {
	font-family: "Poppins", sans-serif;
}
.manrope {
	font-family: "Manrope", sans-serif;
}
.ubuntu {
	font-family: "Ubuntu", sans-serif;
}
/* .app_cnt {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
} */
/* .routes {
  width: 100vw;
  height: calc(100vh - 60px);
  overflow: hidden;
  overflow-y: auto;
} */
a {
	text-decoration: none;
}
*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: inherit;
}
:root {
	--bg: #f7f7f7;
	--txt: #282c4b;
	--yellow: #f17216;
	--red: #e33861;
}
.containerh {
	padding-top: 2%;
	padding-bottom: 2%;
}
.flex {
	display: flex;
}
.justify-center {
	justify-content: center;
}
.align-center {
	align-items: center;
}
.justify-between {
	justify-content: space-between;
}

.container {
	padding-left: 12%;
	padding-right: 12%;
}
.bg {
	background-color: var(--bg);
}
.logos_col {
	margin-top: 50px;
	margin-bottom: 50px;
}
.logos_col img {
	height: 40px;
	margin-right: 20px;
}
.error-text {
	color: var(--red);
	font-weight: 600;
	font-size: 0.8em;
}
@media (max-width: 760px) {
	.desktop {
		display: none !important;
	}
	.mobile {
		display: block;
	}
	.mobile.flex {
		display: flex;
	}
	.mfc {
		flex-direction: column;
	}
}

@media (min-width: 760px) {
	.desktop {
		display: block;
	}
	.mobile {
		display: none;
	}
	.desktop.flex {
		display: flex;
	}
}
.c404 {
	width: 100vw;
	height: calc(100vh - 60px);
	font-size: 3em;
	color: var(--txt);
	opacity: 0.6;
}
.lp .c404 {
	width: calc(100vw - 10%);
}
.btn {
	border: 1px solid #e33861;
}
.btn .loader div {
	border-width: 2px;
	width: 20px;
	height: 20px;
}
.htitle {
	font-weight: 700;
	color: #282c4b;
	font-size: 2.3em;
	font-family: "Ubuntu", sans-serif;
	margin-bottom: 30px;
	position: relative;
}
.htitle::before {
	content: "";
	position: absolute;
	bottom: -10px;
	width: 5%;
	height: 7px;
	background-color: #e33861;
}

@media (max-width: 760px) {
	.htitle {
		font-size: 1.5em;
		margin-top: 20px;
	}
	.container {
		padding-left: 5%;
		padding-right: 5%;
	}
	.header-padding {
		padding-left: 10%;
		padding-right: 5%;
	}

	.htitle::before {
		height: 5px;
		bottom: -5px;
	}
}
.prc button {
	width: 200px;
}
.prc {
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/* width: fit-content; */
	margin-top: 20px;
	padding: 20px;
	padding-right: 40px;
}
.jdah::before {
	display: none;
}
.prc img {
	width: 200px;
	height: 200px;
	object-fit: contain;
}
.prc h3 {
	font-size: 2em;
}

.prc span {
	color: #e33861;
}
