.ideas {
	background-color: white;
}
.sidea {
	background: #ffffff;
	box-shadow: 0px 10px 45px rgba(22, 22, 22, 0.15);
	padding: 20px;
	margin: 15px 0px;
}
.sidea h3 {
	color: #e33861;
	font-weight: 800;
}
.sidea ul {
	/* list-style: none; */
	font-size: 0.8em;
	color: #727171;
	padding: 20px;
	padding-right: 0px;
	padding-top: 10px;
}
.sidea p {
	color: #212121;
	margin-top: 10px;
	font-size: 0.8em;
	font-weight: 500;
}
.btts {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
}
.btts button {
	width: 45%;
	border-radius: 20px;
	border-width: 0px;
	font-weight: 800;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	overflow: hidden;
}
.btts button svg {
	margin-left: 10px;
}
.btts button:first-child {
	padding: 10px;
	color: white;
	background: #e33861;
}
.btts button:last-child {
	background-color: white;
	background: none;
	border: 2px solid #d9d9d9;
	color: black;
	padding: 10px;
}
.ideas_list {
	justify-content: space-between;
}
.sidea {
	position: relative;
}
.sidea .img {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
}
.sidea .img img {
	max-width: 100%;
	object-fit: contain;
}
.btts button:first-child a {
	color: white;
}
.btts button:last-child {
	color: black;
}
.btn_vi {
	margin-bottom: 50px;
	background-color: white !important;
	color: #e33861;
	font-weight: 800;
	border-width: 2px;
}
.funded {
	background-color: #e33861;
	width: fit-content;
	color: white;
	padding: 10px 15px;
	font-size: 0.9em;
	position: absolute;
	top: 0;
	left: 0;
}
.btn_ka {
	padding: 10px 20px;
	cursor: pointer;
	background-color: #da2f72;
	color: white;
	border: none;
	border-color: #da2f72;
	font-weight: 600;
	margin-top: 20px;
}
.steps {
	padding: 0 10px;
}
.steps .heading {
	margin: 20px 0;
	color: #212121;
	font-size: 1.3rem;
}
.list {
	margin-left: 20px;
	color: #727171;
	margin-bottom: 30px;
}
.list li {
	margin-bottom: 10px;
}
.sign {
	padding: 0 20px;
	text-align: center;
}
.sign h2 {
	margin: 20px 0;
	color: #212121;
	font-size: 1.3rem;
}
.sign p {
	color: #727171;
	margin-bottom: 30px;
	line-height: 1.4rem;
}
.sign p span {
	color: #da2f72;
	font-weight: 600;
}
.payment-form > * {
	margin-bottom: 20px;
	width: 100%;
}
.payment-form > select,
input {
	height: 40px;
	padding: 10px;
}
.payment-form > .error {
	color: #da2f72;
	font-size: 0.85rem;
	margin-top: 10px;
	margin-bottom: 0;
}
.loader_wrapper {
	min-height: 50vh;
	width: 100vw;
	display: grid;
	place-items: center;
}
@media (min-width: 760px) {
	.sidea {
		width: 360px;
		margin: 20px;
	}
	.plist {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}
